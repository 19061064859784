import React from 'react';
import Styles from '../css/resizable_movable_image.module.css';
import {DraggableCore} from 'react-draggable';
import ImageWarning from './image_warning';

class ResizableMovableImage extends React.Component {
  constructor(props) {
    super(props);

    this.image = React.createRef();
    this.imageContainer = React.createRef();

    this.scaleBackground = this.scaleBackground.bind(this);
    this.moveBackground = this.moveBackground.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);

    this.state = {
      imageWidth: 100,
      hasMoved: false,
      hasZoomed: false,
    }
  }

  componentDidUpdate() {
    if (this.state.imageWidth !== this.props.imageZoom) {
      this.setState({
        imageWidth: this.props.imageZoom,
      });
    }
  }

  imageLoaded() {
    this.props.onImageLoaded(this.image.current);
  }

  moveBackground(_, {deltaX, deltaY}) {
    const [existingX, existingY] = this.props.imagePosition;
    const coordinates = [existingX + deltaX, existingY + deltaY];

    this.setState({
      hasMoved: true,
    });

    this.props.onImagePositionUpdate(coordinates);
  }

  scaleBackground(event) {
    const newZoom = this.state.imageWidth + (event.deltaY / 10);

    this.setState({
      imageWidth: newZoom,
      hasZoomed: true,
    });

    this.props.onImageZoomUpdate(newZoom);

    event.preventDefault();
  }

  render() {
    return (
      <div
        ref={this.imageContainer}
        className={`
          ${Styles.imageContainer}
          ${this.props.cardSize === 'large' ? Styles.imageContainerLarge : ''}
        `}
      >
        {this.props.enableWarnings &&
          <ImageWarning
            image={this.image}
            imageContainer={this.imageContainer}
            hasMoved={this.state.hasMoved}
            hasZoomed={this.state.hasZoomed}
            onDisableDownload={this.props.onDisableDownload}
          />
        }

        <div>
          <DraggableCore
            onDrag={this.moveBackground}
          >
            <div
              onWheel={this.scaleBackground}
              className={Styles.sizingContainer}
              style={{
                width: `${this.state.imageWidth}%`,
                left: this.props.imagePosition[0],
                top: this.props.imagePosition[1],
              }}
            >
              <img
                draggable="false"
                ref={this.image}
                alt="background"
                src={this.props.image}
                className={Styles.image}
                onLoad={this.imageLoaded}
              />
            </div>
          </DraggableCore>
        </div>
      </div>
    );
  }
}

ResizableMovableImage.defaultProps = {
  imagePosition: [0, 0],
};

export default ResizableMovableImage;

