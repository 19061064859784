import React from 'react';
import Styles from '../css/lyric_card.module.css';
import ContentEditable from 'react-contenteditable';
import ResizableMovableImage from './resizable_movable_image';
import Logo from '-!svg-react-loader?name=Logo!../assets/wordmark.svg';
import Smartquotes from 'smartquotes';
import ColorThief from 'color-thief-browser';
import RGBToHex from 'rgb-hex';

class LyricCard extends React.Component {
  constructor(props, ref) {
    super(props);

    this.card = React.createRef();

    this.lyricUpdate = this.lyricUpdate.bind(this);
    this.captionUpdate = this.captionUpdate.bind(this);
    this.captionBlur = this.captionBlur.bind(this);
    this.imageMove = this.imageMove.bind(this);
    this.imageZoom = this.imageZoom.bind(this);
    this.setColorsBasedOnImage = this.setColorsBasedOnImage.bind(this);

    this.state = {
      color: this.props.color,
      referentTop: 'auto',
      referentBottom: this.props.cardSize === 'large' ? 'calc(200px + 90px)' : 'calc(164px + 66px)',
      referentLeft: this.props.cardSize === 'large' ? '90px' : '66px',
      referentRight: 'auto',
      referentFontSize: this.props.cardSize === 'large' ? '65px' : '45px',
      referentTextAlign: 'left',
      imageBlackAndWhite: false,
    };
  }

  componentDidMount() {
    this.card.current.addEventListener('paste', (event) => {
      event.preventDefault();
      const text = event.clipboardData.getData('text/plain');
      const formattedText = Smartquotes(text);
      document.execCommand('insertHTML', false, formattedText);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.referentFontSize !== prevProps.referentFontSize
      || this.props.referentPosition !== prevProps.referentPosition
    ) {
      this.updateReferent();
    }
  }

  getCardRef() {
    return this.card.current;
  }

  updateReferent() {
    this.setReferentFontSize();
    this.setReferentPosition();
  }

  setReferentFontSize() {
    if (this.props.referentFontSize === 'normal') {
      this.setState({
        referentFontSize: this.props.cardSize === 'large' ? '65px' : '45px',
      });
    }

    if (this.props.referentFontSize === 'large') {
      this.setState({
        referentFontSize: this.props.cardSize === 'large' ? '75px' : '72px',
      });
    }
  }

  setReferentPosition() {
    if (this.props.referentPosition === 'bottom_left') {
      this.setState({
        referentTop: 'auto',
        referentBottom: this.props.cardSize === 'large' ? 'calc(200px + 90px)' : 'calc(164px + 66px)',
        referentLeft: this.props.cardSize === 'large' ? '90px' : '66px',
        referentRight: 'auto',
        referentTextAlign: 'left',
      });
    }

    if (this.props.referentPosition === 'top_left') {
      this.setState({
        referentTop: this.props.cardSize === 'large' ? '90px' : '66px',
        referentBottom: 'auto',
        referentLeft: this.props.cardSize === 'large' ? '90px' : '66px',
        referentRight: 'auto',
        referentTextAlign: 'left',
      });
    }

    if (this.props.referentPosition === 'bottom_right') {
      this.setState({
        referentTop: 'auto',
        referentBottom: this.props.cardSize === 'large' ? 'calc(200px + 90px)' : 'calc(164px + 66px)',
        referentLeft: 'auto',
        referentRight: this.props.cardSize === 'large' ? '90px' : '66px',
        referentTextAlign: 'right',

      });
    }

    if (this.props.referentPosition === 'top_right') {
      this.setState({
        referentTop: this.props.cardSize === 'large' ? '90px' : '66px',
        referentBottom: 'auto',
        referentLeft: 'auto',
        referentRight: this.props.cardSize === 'large' ? '90px' : '66px',
        referentTextAlign: 'right',
      });
    }
  }

  colorUpdate(color) {
    this.props.onColorUpdate(color);
    this.props.resetDownload();
  }

  colorGraynessScore(rgb) {
    const defaultValue = 0;
    return rgb.reduce((accumulator, channel, _, channels) => {
      return accumulator + channels.reduce((_, otherChannel) => {
        return Math.abs(channel - otherChannel);
      });
    }, defaultValue);
  }

  isImageBlackAndWhite(image) {
    const palette = ColorThief.prototype.getPalette(image);

    const paletteGrayness = palette.map(rgb => {
      return this.colorGraynessScore(rgb);
    });

    const maxGrayValue = Math.max(...paletteGrayness);

    return maxGrayValue < 20;
  }

  setColorsBasedOnImage(image) {
    this.setState({
      imageBlackAndWhite: this.isImageBlackAndWhite(image),
    });

    const dominantColor = ColorThief.prototype.getColor(image);
    this.colorUpdate(`#${RGBToHex(...dominantColor)}`);
  }

  imageMove(value) {
    this.props.onImagePositionUpdate(value);
    this.props.resetDownload();
  }

  imageZoom(value) {
    this.props.onImageZoomUpdate(value);
    this.props.resetDownload();
  }

  lyricUpdate(event) {
    this.props.onLyricUpdate(event);
    this.props.resetDownload();
  }

  captionBlur(event) {
    this.props.onCaptionBlur(event);
  }

  captionUpdate(event) {
    this.props.onCaptionUpdate(event);
    this.props.resetDownload();
  }

  render() {
    return (
      <div
        className={Styles.cardScaleClippingMask}
        style={{
          width: `calc(${this.props.width} * ${this.props.scale})`,
          height: `calc(${this.props.height} * ${this.props.scale})`,
        }}
      >
        <div
          ref={this.card}
          className={Styles.card}
          style={{
            width: this.props.width,
            height: this.props.height,
            background: this.props.color,
            transform: `scale(${this.props.scale})`,
          }}
        >
          <ResizableMovableImage
            image={this.props.image}
            imagePosition={this.props.imagePosition}
            onImagePositionUpdate={this.imageMove}
            imageZoom={this.props.imageZoom}
            onImageLoaded={this.setColorsBasedOnImage}
            onImageZoomUpdate={this.imageZoom}
            enableWarnings={this.props.enableWarnings}
            onDisableDownload={this.props.onDisableDownload}
            cardSize={this.props.cardSize}
          />

          <div
            className={`
              ${Styles.lyric}
              ${this.state.imageBlackAndWhite ? Styles.lyricWhite : Styles.lyricBlack}
              ${this.props.cardSize === 'large' ? Styles.lyricLarge : ''}
              ${this.state.referentTextAlign === 'right' ? Styles.lyricRight : ''}
            `}
            style={{
              top: this.state.referentTop,
              bottom: this.state.referentBottom,
              left: this.state.referentLeft,
              right: this.state.referentRight,
              fontSize: this.state.referentFontSize,
            }}
          >
            <ContentEditable
              spellCheck="false"
              html={this.props.lyric}
              onChange={this.lyricUpdate}
            />
          </div>

          <div
            className={`
              ${Styles.caption}
              ${this.props.cardSize === 'large' ? Styles.captionLarge : ''}
              ${this.props.captionSize === 'small' ? Styles.captionSmall : ''}
            `}
            style={{
              background: this.props.color,
              color: this.props.textTheme,
              borderColor: this.props.textTheme,
            }}
          >
            <ContentEditable
              spellCheck="false"
              html={this.props.caption}
              onChange={this.captionUpdate}
              onBlur={this.captionBlur}
            />
            <Logo
              className={`${Styles.logo} ${this.props.cardSize === 'large' ? Styles.logoLarge : ''}`}
              fill={this.props.textTheme}
            />
         </div>
        </div>
      </div>
    );
  }
}

LyricCard.defaultProps = {
  scale: 1,
  textTheme: '#ffffff',
};

export default LyricCard;
