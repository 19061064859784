import React from 'react';
import Styles from '../css/warning.module.css';

class ImageWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pixelated: false,
      outOfBounds: false,
      containerBounds: null,
    };
  }

  componentDidUpdate(prevProps) {
    const pixelated = this.isImagePixelated();
    const outOfBounds = this.runImageOutOfBounds();

    if (
      (this.props.hasMoved !== prevProps.hasMoved
      || this.props.hasZoomed !== prevProps.hasZoomed)
      && (this.props.hasMoved
      && this.props.hasZoomed)
    ) {
      this.props.onDisableDownload(false);
    }

    if (
      (this.props.hasMoved
      && this.props.hasZoomed)
      && (this.state.pixelated !== pixelated
      || this.state.outOfBounds !== outOfBounds)
    ) {
      this.setState({
        pixelated: pixelated,
        outOfBounds: outOfBounds,
      });

      this.props.onDisableDownload(outOfBounds);
    }
  }

  isImagePixelated() {
    const image = this.props.image.current;
    const heightPixelatedAmount = image.height / image.naturalHeight;
    const widthPixelatedAmount = image.width / image.naturalWidth;
    const pixelatedAmount = Math.max(heightPixelatedAmount, widthPixelatedAmount).toFixed(2);
    return pixelatedAmount > 2 ? pixelatedAmount : false;
  }

  isImageOutOfBounds() {
    const imageBounds = this.props.image.current.getBoundingClientRect();
    const containerBounds = this.state.containerBounds;

    const xOffset = imageBounds.x - containerBounds.x;
    const isXOutOfBounds = imageBounds.width + xOffset < containerBounds.width || xOffset > 0;

    const yOffset = imageBounds.y - containerBounds.y;
    const isYOutOfBounds = imageBounds.height + yOffset < containerBounds.height || yOffset > 0;

    return isXOutOfBounds || isYOutOfBounds;
  }

  runImageOutOfBounds() {
    if (this.state.containerBounds) {
      return this.isImageOutOfBounds();
    } else {
      const imageContainer = this.props.imageContainer.current;

      this.setState({
        containerBounds: imageContainer.getBoundingClientRect(),
      }, () => this.isImageOutOfBounds);
    }
  }

  render() {
    return (
      <div className={`${Styles.message} ${this.state.pixelated || this.state.outOfBounds ? Styles.messageWarning : ''}`}>
        {this.state.pixelated &&
          <div className={Styles.lineItem}>Too pixelated, zoom out</div>
        }
        {this.state.outOfBounds &&
          <div className={Styles.lineItem}>Out of bounds</div>
        }
        {!this.props.hasMoved &&
          <div className={Styles.lineItem}>Step 1: Drag the image to move it</div>
        }
        {!this.props.hasZoomed &&
          <div className={Styles.lineItem}>Step 2: Scroll on the image to scale it</div>
        }
      </div>
    );
  }
}

ImageWarning.defaultProps = {
  hasMoved: false,
  hasZoomed: false,
};

export default ImageWarning;
