import React from 'react';
import LyricCard from './lyric_card';
import DownloadButton from './download_button';
import Styles from '../css/layout.module.css';

class LyricCardHalfSize extends React.Component {
  constructor(props) {
    super(props);

    this.downloadButton = React.createRef();
    this.cardComponent = React.createRef();

    this.imagePositionUpdate = this.imagePositionUpdate.bind(this);
    this.imageZoomUpdate = this.imageZoomUpdate.bind(this);
    this.resetDownload = this.resetDownload.bind(this);
    this.disableDownload =this.disableDownload.bind(this);

    this.state = {
      imagePosition: [0, 0],
      imageZoom: 100,
      cardRef: null,
      disableDownload: true,
    };
  }

  componentDidMount() {
    const cardComponentRef = this.cardComponent.current.getCardRef();
    this.setState({
      cardRef: cardComponentRef,
    });
  }

  resetDownload() {
    this.downloadButton.current.resetDownload();
  }

  imagePositionUpdate(coordinates) {
    this.setState({
      imagePosition: coordinates,
    });
  }

  imageZoomUpdate(value) {
    this.setState({
      imageZoom: value
    });
  }

  disableDownload(value) {
    this.setState({
      disableDownload: value
    });
  }

  render() {
    return (
      <div className={Styles.card_container}>
        <div className={Styles.card_label}>{this.props.label}</div>

        <DownloadButton
          ref={this.downloadButton}
          card={this.state.cardRef}
          cardWidth={this.props.width}
          cardHeight={this.props.height}
          offset={this.props.downloadButtonOffset}
          disabled={this.state.disableDownload}
        />

        <LyricCard
          width={this.props.width}
          height={this.props.height}
          image={this.props.image}
          imagePosition={this.state.imagePosition}
          onImagePositionUpdate={this.imagePositionUpdate}
          imageZoom={this.state.imageZoom}
          onImageZoomUpdate={this.imageZoomUpdate}
          color={this.props.color}
          onColorUpdate={this.props.onColorUpdate}
          caption={this.props.caption}
          captionSize={this.props.captionSize}
          onCaptionUpdate={this.props.onCaptionUpdate}
          onCaptionBlur={this.props.onCaptionBlur}
          lyric={this.props.lyric}
          onLyricUpdate={this.props.onLyricUpdate}
          resetDownload={this.resetDownload}
          referentFontSize={this.props.referentFontSize}
          referentPosition={this.props.referentPosition}
          textTheme={this.props.textTheme}
          scale=".4"
          enableWarnings="true"
          onDisableDownload={this.disableDownload}
          cardSize={this.props.cardSize}
        />

        <div className={Styles.card_offset}>
          <LyricCard
            ref={this.cardComponent}
            width={this.props.width}
            height={this.props.height}
            image={this.props.image}
            imagePosition={this.state.imagePosition}
            onImagePositionUpdate={this.imagePositionUpdate}
            imageZoom={this.state.imageZoom}
            onImageZoomUpdate={this.imageZoomUpdate}
            color={this.props.color}
            onColorUpdate={this.props.onColorUpdate}
            caption={this.props.caption}
            captionSize={this.props.captionSize}
            onCaptionUpdate={this.props.onCaptionUpdate}
            onCaptionBlur={this.props.onCaptionBlur}
            lyric={this.props.lyric}
            onLyricUpdate={this.props.onLyricUpdate}
            resetDownload={this.resetDownload}
            referentFontSize={this.props.referentFontSize}
            referentPosition={this.props.referentPosition}
            textTheme={this.props.textTheme}
            cardSize={this.props.cardSize}
          />
        </div>
      </div>
    );
  }
}

LyricCardHalfSize.defaultProps = {
  cardSize: "small",
};

export default LyricCardHalfSize;

