import React from 'react';
import ColorPicker from './color_picker';

class Colors extends React.Component {
  constructor(props) {
    super(props);

    this.setColor = this.setColor.bind(this);
  }

  setColor(color) {
    this.props.setColor(color);
  }

  render() {
    return (
      <ColorPicker
        setColor={this.setColor}
      />
    );
  }
}

export default Colors;

