import React from 'react';
import Styles from '../css/color.module.css';
import Spectrum from '../assets/spectrum.png';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.colorPicker = React.createRef();

    this.colorChange = this.colorChange.bind(this);
    this.activateColorPicker = this.activateColorPicker.bind(this);

    this.state = {
      color: '#ffffff',
    }
  }

  activateColorPicker() {
    this.colorPicker.current.click();
  }

  colorChange({target: {value: color}}) {
    this.setState({
      color: color,
    }, () => {
      this.props.setColor(color);
    });
  }

  render() {
    return (
      <div>
        <div
          className={Styles.colorPicker}
          onClick={this.activateColorPicker}
          style={{
            backgroundImage: `url(${Spectrum})`,
          }}
        ></div>

        <input
          ref={this.colorPicker}
          type="color"
          value={this.state.color}
          onChange={this.colorChange}
          style={{
            display: 'none',
          }}
        />
      </div>
    );
  }
}

export default ColorPicker;

