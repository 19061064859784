import React from 'react';
import Colors from './colors';
import Styles from '../css/controls.module.css';

class Controls extends React.Component {
  render() {
    return (
      <div className={Styles.controls}>
        {!this.props.image &&
          <div className={Styles.placeholder}>
            <div>To get started, drag and drop an image, or paste an image or image url</div>
            <div><a target="_blank" rel="noopener noreferrer" href="https://youtu.be/2zReEaM1xBg">Tutorial video</a></div>
          </div>
        }

        {this.props.image &&
          <div className={Styles.options}>
            <div className={Styles.optionsGroup}>
              <Colors
                setColor={this.props.setColor}
                image={this.props.image}
              />
            </div>

            <div className={Styles.optionsGroup}>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setCaptionSize('medium')}
              >Md Caption</div>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setCaptionSize('small')}
              >Sm Caption</div>
            </div>

            <div className={Styles.optionsGroup}>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setReferentFontSize('normal')}
              >Md Lyric</div>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setReferentFontSize('large')}
              >Lg Lyric</div>
            </div>

            <div className={Styles.optionsGroup}>
              <div
                className={`${Styles.optionGray} ${Styles.textThemeWhite}`}
                style={{
                  margin: '0',
                }}
                onClick={() => this.props.setTextTheme('#ffffff')}
              >White caption</div>
              <div
                className={`${Styles.optionGray} ${Styles.textThemeBlack}`}
                onClick={() => this.props.setTextTheme('#000000')}
              >Black caption</div>
            </div>

            <div className={Styles.optionsGroup}>
              <div
                className={`${Styles.optionGray} ${Styles.positionBottom}`}
                onClick={() => this.props.setReferentPosition('bottom_left')}
              >Bottom left</div>
              <div
                className={`${Styles.optionGray} ${Styles.positionTop}`}
                onClick={() => this.props.setReferentPosition('top_left')}
              >Top left</div>
              <div
                className={`${Styles.optionGray} ${Styles.positionBottom}`}
                onClick={() => this.props.setReferentPosition('bottom_right')}
              >Bottom right</div>
              <div
                className={`${Styles.optionGray} ${Styles.positionTop}`}
                onClick={() => this.props.setReferentPosition('top_right')}
              >Top right</div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Controls;

