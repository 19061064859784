import React from 'react';
import Smartquotes from 'smartquotes';
import Helmet from 'react-helmet';
import Html2Text from 'html2plaintext';
import TinyColor from 'tinycolor2';
import LyricCardHalfSize from '../components/lyric_card_half_size';
import LyricCardPlaceholder from '../components/lyric_card_placeholder';
import DropSurface from '../components/drop_surface';
import Controls from '../components/controls';
import Styles from '../css/layout.module.css';
import '../css/body.css';
import '../css/fonts.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.setColor = this.setColor.bind(this);
    this.colorUpdate = this.colorUpdate.bind(this);
    this.imageData = this.imageData.bind(this);
    this.setCaptionSize = this.setCaptionSize.bind(this);
    this.captionUpdate = this.captionUpdate.bind(this);
    this.captionBlur = this.captionBlur.bind(this);
    this.lyricUpdate = this.lyricUpdate.bind(this);
    this.setReferentFontSize = this.setReferentFontSize.bind(this);
    this.setReferentPosition = this.setReferentPosition.bind(this);
    this.setTextTheme = this.setTextTheme.bind(this);

    this.state = {
      image: null,
      color: '#000000',
      caption: 'Artist, “Song Name”',
      lyric: '<div>Click to edit</div><div>Press enter to insert new lines</div><div>Or just paste some text</div>',
      referentFontSize: 'normal',
      referentPosition: 'bottom',
      textTheme: '#ffffff',
    };
  }

  setColor(color) {
    const textTheme = TinyColor.mostReadable(color, ['#000000', '#ffffff']).toHexString();

    this.setState({
      color: color,
      textTheme: textTheme,
    });
  }

  setCaptionSize(captionSize) {
    this.setState({
      captionSize: captionSize,
    });
  }

  imageData(imageBlobUrl) {
    this.setState({
      image: imageBlobUrl,
    });
  }

  captionUpdate({target: {value}}) {
    let plaintext = Html2Text(value);

    if (plaintext.includes('\n')) {
      plaintext = plaintext.replace('\n', ' ');
    }

    this.setState({
      caption: plaintext,
    });
  }

  colorUpdate(color) {
    this.setColor(color);
  }

  captionBlur({target: {textContent}}) {
    const formattedCaption = Smartquotes(textContent);

    this.setState({
      caption: formattedCaption,
    });
  }

  lyricUpdate({target: {value}}) {
    let newContent = value;

    if (newContent.includes('<br>')) {
      newContent = newContent.replace('<br>', '');
    }

    if (newContent.includes('<div></div>')) {
      newContent = newContent.replace('<div></div>', '');
    }

    if (!newContent.includes('<div>')) {
      newContent = `<div>${newContent}</div>`;
    }

    if (newContent.split("\n").length) {
      newContent = newContent.replace(/\n/g, '</div><div>');
    }

    this.setState({
      lyric: newContent,
    });
  }

  setReferentFontSize(value) {
    this.setState({
      referentFontSize: value,
    });
  }

  setReferentPosition(value) {
    this.setState({
      referentPosition: value,
    });
  }

  setTextTheme(value) {
    this.setState({
      textTheme: value,
    });
  }

  render() {
    return (
      <div>
        <DropSurface
          onImage={this.imageData}
        />

        <Controls
          image={this.state.image}
          setColor={this.setColor}
          setCaptionSize={this.setCaptionSize}
          setReferentFontSize={this.setReferentFontSize}
          setReferentPosition={this.setReferentPosition}
          setTextTheme={this.setTextTheme}
        />

        {!this.state.image &&
          <div className={Styles.card_layout}>
            <LyricCardPlaceholder
              width="1080px"
              height="1080px"
              label="Instagram"
              scale=".4"
            />

            <LyricCardPlaceholder
              width="1920px"
              height="1120px"
              label="Twitter"
              scale=".4"
            />
          </div>
        }

        {this.state.image &&
          <div className={Styles.card_layout}>
            <LyricCardHalfSize
              width="1080px"
              height="1080px"
              image={this.state.image}
              color={this.state.color}
              onColorUpdate={this.colorUpdate}
              caption={this.state.caption}
              captionSize={this.state.captionSize}
              onCaptionUpdate={this.captionUpdate}
              onCaptionBlur={this.captionBlur}
              lyric={this.state.lyric}
              onLyricUpdate={this.lyricUpdate}
              referentFontSize={this.state.referentFontSize}
              referentPosition={this.state.referentPosition}
              textTheme={this.state.textTheme}
              downloadButtonOffset="102"
              label="Instagram"
            />

            <LyricCardHalfSize
              width="1920px"
              height="1120px"
              image={this.state.image}
              color={this.state.color}
              onColorUpdate={this.colorUpdate}
              caption={this.state.caption}
              captionSize={this.state.captionSize}
              onCaptionUpdate={this.captionUpdate}
              onCaptionBlur={this.captionBlur}
              lyric={this.state.lyric}
              onLyricUpdate={this.lyricUpdate}
              referentFontSize={this.state.referentFontSize}
              referentPosition={this.state.referentPosition}
              textTheme={this.state.textTheme}
              cardSize="large"
              label="Twitter"
            />
          </div>
        }

        <Helmet
          defaultTitle="Lyric Card Maker"
        />
      </div>
    );
  }
}

export default App;
