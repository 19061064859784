import React from 'react';
import Styles from '../css/layout.module.css';

class LyricCardPlaceholder extends React.Component {
  render() {
    return (
      <div className={Styles.card_container}>
        <div className={Styles.card_label}>{this.props.label}</div>

        <div
          style={{
            width: `calc(${this.props.width} * ${this.props.scale})`,
            height: `calc(${this.props.height} * ${this.props.scale})`,
            background: '#cccccc',
          }}
        ></div>
      </div>
    );
  }
}

export default LyricCardPlaceholder;


